import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDOJULw41M9yQEMqIa1mBYf8zNf94vzMx8",
  authDomain: "espacio-zero.firebaseapp.com",
  projectId: "espacio-zero",
  storageBucket: "espacio-zero.appspot.com",
  messagingSenderId: "381542309926",
  appId: "1:381542309926:web:fa32bd8544c31e460006e7"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
