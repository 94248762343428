import '../pages.css';
import Footer from '../../components/organisms/Footer/Footer';
import ScheduleTrapecio from '../../components/molecules/ActivitiesPages/Schedule/ScheduleTrapecio';

const Trapecio = () => {
    return (
        <div>
            <ScheduleTrapecio/>
            <Footer/>
        </div>
    )
}

export default Trapecio
