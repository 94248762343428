import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "../components/organisms/Navbar/Navbar";
import { ScrollToTop } from "../components/atoms/Scroll/ScrollUp";
import Home from "../pages/Home/Home";
import Contact from "../pages/Contact/Contact";
import Acrobacia from "../pages/Acrobacia/Acrobacia";
import CamaElastica from "../pages/CamaElastica/CamaElastica";
import Circo from "../pages/Circo/Circo";
import DobleRiesgo from "../pages/DobleRiesgo/DobleRiesgo";
import Escalada from "../pages/Escalada/Escalada";
import ParadaDeManos from "../pages/ParadaDeManos/ParadaDeManos";
import Parkour from "../pages/Parkour/Parkour";
import Tela from "../pages/Tela/Tela";
import Trapecio from "../pages/Trapecio/Trapecio";
import ShuiLu from "../pages/ShuiLu/ShuiLu";
import Page404 from "../pages/404/Page404";

const Router = () => {

    return (
        <BrowserRouter>
            <Navbar/>
            <ScrollToTop>
                <Routes>
                    <Route path="/">
                        <Route index element={<Home/>}/>
                        <Route path="contacto" element={<Contact/>}/>
                        <Route path="acrobacia" element={<Acrobacia/>}/>
                        <Route path="cama-elastica" element={<CamaElastica/>}/>
                        <Route path="circo" element={<Circo/>}/>
                        <Route path="dobles-de-riesgo" element={<DobleRiesgo/>}/>
                        <Route path="escalada" element={<Escalada/>}/>
                        <Route path="parada-de-manos" element={<ParadaDeManos/>}/>
                        <Route path="park-our" element={<Parkour/>}/>
                        <Route path="shui-lu" element={<ShuiLu/>}/>
                        <Route path="tela" element={<Tela/>}/>
                        <Route path="trapecio" element={<Trapecio/>}/>
                        <Route path="*" element={<Page404/>}/>
                    </Route>
                </Routes>
            </ScrollToTop>
        </BrowserRouter>
    )
}

export default Router