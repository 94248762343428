import './schedule.css';
import { Link } from 'react-router-dom';
import { GoCheck } from 'react-icons/go';
import { FaChild,FaChevronRight } from 'react-icons/fa';
import Img from '../../../../assets/activities/shuilu6.jpeg';

const ScheduleShuiLu = () => {
    return (
        <div className='container-ScheduleSection'>
            <div className='ScheduleSection'>
                <div className='description-ScheduleSection'>
                    
                    <div className='container-subtitle-ScheduleSection'>
                        <h3 className='subtitle-ScheduleSection'>Clases</h3>
                        <span className='titleStyleBottom-ScheduleSection'></span>
                    </div>

                    <div className='container-cardsScheduleSection'>
                        <div className='container-cardSchedule'>
                            <div className='cardSchedule'>
                                <div className='container-imgInfoCardSchedule'>
                                    <img src={Img} alt='Shui Lu'/>   
                                </div>
                                <div className='container-infoCardSchedule'>

                                    <h4 className='news-title activity'>Shui Lu</h4>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <FaChevronRight className='icon-ScheduleSection'/>
                                        <h4 className='title-scheduleByGroup-ScheduleSection'>Días y horarios</h4>
                                    </div>
                                    <div className='container-spans-ScheduleSection'>
                                        <span className='days-ScheduleSection'>Martes y jueves 20hs.</span>
                                    </div>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <FaChild className='icon-ScheduleSection'/>
                                        <h4 className='title-scheduleByGroup-ScheduleSection'>Profe Juan Beccaglia</h4>
                                    </div>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <GoCheck className='icon-ScheduleSection'/>
                                        <span className='title-scheduleByGroup-ScheduleSection'><Link to='/contacto'>Abierta la inscripción</Link></span>
                                    </div>

                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScheduleShuiLu
