import { Link } from 'react-router-dom';
import './sectionInscription.css';

const SectionInscription = () => {
    return (
        <div className='container-sectionInscription'>
            <div className='container2-sectionInscription'>
                <div className='container-contentSectionInscription'>
                    <h5 className='title-sectionInscription'>INSCRIPCIONES ABIERTAS</h5>
                    <Link to='/contacto'><h6 className='linkContact-sectionInscription'>Quiero comunicarme</h6></Link>
                </div>  
            </div>
            
        </div>
    )
}

export default SectionInscription
