// import logo from '../../../assets/logo-blanco.png';
import logo from '../../../assets/logo_espaciozero.png';
import { Link } from 'react-router-dom';

const Logo = () => {
    return (
        <Link to='/' >
            <img src={logo} alt='Espacio Zero' />
        </Link>
    )
}

export default Logo
