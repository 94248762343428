import '../../organisms/Navbar/navbar.css';
import Logo from '../../atoms/Navbar/Logo';
import { GiHamburgerMenu } from 'react-icons/gi';
import { HiOutlineMail } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

const MobileNavigation = ( {sidebarOpen, onClickSidebarOpen} ) => {

    const navigate = useNavigate();

    const onClickSidebar = () => {
        onClickSidebarOpen(!sidebarOpen);
    };

    const onRedirection = () => {
        navigate('/contacto');
    };

    return (
        <div className='container-navbar-mobile'>
            <div className='containter-iconMenu-mobile'>
                <GiHamburgerMenu className='iconMenu-mobile' onClick={onClickSidebar}/>
            </div>
            <div className='container-logoMobile'>
                <Logo/>
            </div>
            <div className='container-contactButtonMobile'>
                <button className='contactButtonMobile' onClick={onRedirection}>
                   <HiOutlineMail className='contact-iconMobile'/> 
                </button>
            </div>
        </div>
    )
}

export default MobileNavigation
