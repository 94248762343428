import '../pages.css';
import Footer from '../../components/organisms/Footer/Footer';
import ScheduleShuiLu from '../../components/molecules/ActivitiesPages/Schedule/ScheduleShuiLu';
import VideoSection from '../../components/molecules/ActivitiesPages/VideoSection/VideoSection';

const ShuiLu = () => {
    return (
        <div>
            <ScheduleShuiLu/>
            {/* <VideoSection/> */}
            <Footer/>
        </div>
    )
}

export default ShuiLu
