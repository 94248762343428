import './contactForm.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Toaster, toast } from 'react-hot-toast';
import axios from 'axios';

const ContactForm = () => {

    const baseUrl = 'https://espacio-zero-backend.vercel.app/api/send-email';

    return (
        <div className='contactSectionForm'>
            <div className='container-title-ContactSectionForm'>
                <h3 className='title-ContactSectionForm'>Envianos tu mensaje</h3>
                <span className='titleStyleBottom-ContactSectionForm'></span>
            </div>
            <Formik
                initialValues={{
                    firstname: '',
                    phone: '',
                    email: '',
                    message: ''
                }}
                validate={(values) => {
                    const errors = {};
                    if (!values.firstname) {
                        errors.firstname = 'Nombre Requerido';
                    }else{
                        if ( (!/^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]*$/i.test(values.firstname) && values.firstname.length > 0) && values.firstname !== '') {
                            errors.firstname = 'Nombre Invalido';
                        }
                    }
                    if ( (!/^([0-9])*$/i.test(values.phone) && values.phone.length > 0) ) {
                        errors.phone = 'Teléfono Invalido';
                    }
                    if(!values.email){
                        errors.email= 'Email Requerido'
                    }
                    if(!values.message){
                        errors.message = 'Mensaje Requerido';
                    }
                    else{
                        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                            errors.email = 'Email Invalido';
                        }
                    }
                    return errors;
                }}
                onSubmit={(values, { resetForm }) => {
                    //petición al back para enviar los datos del form
                    const name = values.firstname;
                    const phone = values.phone;
                    const email = values.email;
                    const message = values.message;

                    const sendMessageForm = async () => {
                        try {
                            const response = await axios.post(`${baseUrl}`, { name, phone, email, message });
                        } catch (error) {
                            console.log(error)
                        }
                    }
                    sendMessageForm();
                    toast.success('Mensaje enviado!');
                    resetForm();
                }}
                >
                {() => 
                    (<div className='container-contactForm'>
                        <Form className="contactForm">
                            <div className="fields">
                                <span className="field">Nombre</span>
                                <Field
                                type="text"
                                className="inputField"
                                placeholder="Ingrese su nombre (*)"
                                name="firstname"                                    
                                />
                                <ErrorMessage className="errorMessage" name="firstname" component="div" />
                            </div>
                            <div className="fields">
                                <span className="field">Teléfono</span>
                                <Field
                                type="text"
                                className="inputField"
                                placeholder="Ingrese su teléfono"
                                name="phone" 
                                />
                                <ErrorMessage className="errorMessage" name="phone" component="div" />
                            </div>
                            <div className="fields">
                                <span className="field">Email</span>
                                <Field
                                type="text"
                                className="inputField"
                                placeholder="Ingrese su email (*)"
                                name="email" 
                                />
                                <ErrorMessage className="errorMessage" name="email" component="div" />
                            </div>
                            <div className="fields">
                                <span className="field">Mensaje</span>
                                <Field
                                as="textarea" rows={5} cols={10} maxLength="150"
                                className="inputField"
                                placeholder="Escriba su mensaje (*)"
                                name="message" 
                                />
                                <ErrorMessage className="errorMessage" name="message" component="div" />
                            </div>
                            <div className="fields">
                                <button type='submit' className="btn-sendMessage">
                                    Enviar
                                </button>
                            </div>
                        </Form>
                    </div>)}
            </Formik>
            <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              // Define default options
              className: '',
              duration: 5000,
              style: {
                background: '#fff',
                color: '#141516',
                fontWeight: 'semibold',
              },
              // Default options for specific types
              success: {
                duration: 3000,
                theme: {
                  primary: 'green',
                  secondary: 'black',
                },
              },
            }}
            />
        </div>
    )
}

export default ContactForm
