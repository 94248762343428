import './footer.css';
import { Link } from 'react-router-dom';
import { AiOutlineInstagram } from 'react-icons/ai';
import { FaYoutube } from 'react-icons/fa';
import { MdPlace, MdMail } from 'react-icons/md';

const Footer = () => {
    return (
        <footer className="container-footer">
            <div className='container2-footer'>

                <div className="container-road-map">
                    <div className="road-map">
                        <div className="title-road-map">ACTIVIDADES</div>
                        <Link to="/acrobacia" className='subtitle-road-map'>Acrobacia</Link>
                        <Link to="/cama-elastica" className='subtitle-road-map'>Cama Elástica</Link>
                        <Link to="/circo" className='subtitle-road-map'>Circo</Link>
                        <Link to="/dobles-de-riesgo" className='subtitle-road-map'>Doble Riesgo</Link>
                        <Link to="/escalada" className='subtitle-road-map'>Escalada</Link>
                        <Link to="/parada-de-manos" className='subtitle-road-map'>Parada de manos</Link>
                        <Link to="/park-our" className='subtitle-road-map'>Parkour</Link>
                        <Link to="/shui-lu" className='subtitle-road-map'>Shui Lu</Link>
                        <Link to="/tela" className='subtitle-road-map'>Tela</Link>
                        <Link to="/trapecio" className='subtitle-road-map'>Trapecio</Link>
                    </div>
                    <div className="road-map">
                        <div className="title-road-map">CONTACTO</div>
                        <span className='subtitle-road-map-contact'><Link to='/contacto' >Enviá tu consulta</Link></span>
                        <div className='subtitle-road-map-location'>
                            <MdMail/>
                            <span className='subtitle-location'><a href='mailto:pruebas.ato.10@gmail.com?Subject=Consulta'>info@espaciozero.com.ar</a></span>
                        </div>
                    </div>
                    <div className="road-map ">
                        <div className="title-road-map">SEDES</div>
                        <div className='subtitle-road-map-location'>
                            <MdPlace/>
                            <span className='subtitle-location'>
                                <a target='_blank' rel ='noreferrer' href='https://www.google.com.ar/maps/place/Espacio+Zero/@-34.5998552,-58.4450423,19z/data=!3m1!4b1!4m5!3m4!1s0x95bccb286276f137:0x8dd792f0ed9f2a4c!8m2!3d-34.5998485!4d-58.4444847'>
                                    Malabia 538, Villa Crespo
                                </a>
                            </span>
                        </div>
                        <div className='subtitle-road-map-location'>
                            <MdPlace/>
                            <span className='subtitle-location'>
                                <a target='_blank' rel ='noreferrer' href='https://www.google.com.ar/maps/place/Espacio+Zero/@-34.5998552,-58.4450423,19z/data=!3m1!4b1!4m5!3m4!1s0x95bccb286276f137:0x8dd792f0ed9f2a4c!8m2!3d-34.5998485!4d-58.4444847'>
                                Murillo 765, Villa Crespo
                                </a>
                            </span>
                        </div>
                    </div>
                    
                    <div className="road-map finally">
                        <div className="container-socialNetworkFooter">
                            <div className='container-circle-socialNetworkFooter'>
                                <a href="https://www.instagram.com/espacio.zero" className="socialNetworkFooter" target='blank'><AiOutlineInstagram/></a>  
                            </div>
                            <div className='container-circle-socialNetworkFooter'>
                                <a href="https://www.youtube.com/channel/UCpugkCgzT_H5FBZtB5UhgAw" className="socialNetworkFooter" target='blank'><FaYoutube/></a>  
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
            
            <div className="container-copyright">
                <div className="copyrightBox">
                    <div className="copyright">© 2023 Espacio Zero. Todos los derechos reservados.</div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
