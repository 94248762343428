import CoverPages from "../../components/atoms/Cover/CoverPages"
import img from '../../assets/logo-espacio-zero.jpg';

const Page404 = () => {

    return (
        <div>
            <CoverPages img={img} alt='' title='Esta ruta no existe. Vuelva a la página principal' classTitle='mainTitle404' />
        </div>
    )
}

export default Page404
