import '../../organisms/Navbar/navbar.css';
import { Link } from 'react-router-dom';
import DropdownMobile from './DropdownMobile';
import { useState } from "react";
import { FaChevronRight } from 'react-icons/fa';

const NavbarLinksMobile = ( {classes, sidebarOpen, onClick} ) => {

    const [dropdown, setDropdown] = useState(true);

    const onClickDropdown = () => {
        setDropdown(!dropdown);
    };
    const onClickLi = () => {
        setTimeout(() => {
            onClick(!sidebarOpen); 
        }, 1);
        
    };
    const onClickLinks = (newValue) => {
        onClick(newValue);
    };

    return (
        <ul className={classes}>
            <div className='container-item-title-sidebar'>
                <FaChevronRight className='item-title-sidebar'/>
                <li onClick={onClickLi}><Link to="/" className='liActivitiesMobile'>INICIO</Link></li>
            </div>
            <div className='activities mobile'>
                <div className='container-item-title-sidebar'>
                    <FaChevronRight className='item-title-sidebar'/>
                    <li
                        onMouseEnter={() => setDropdown(true)}
                        onMouseLeave={() => setDropdown(false)}
                        onClick={onClickDropdown}
                    >
                        <a href="#" className='liActivitiesMobile' onClick={onClickLi}>ACTIVIDADES</a>
                    
                    </li>
                </div>
                <DropdownMobile sidebarOpen={sidebarOpen} onClick={onClickLinks}/>
            </div>
        </ul>
    )
}

export default NavbarLinksMobile