import './coverPages.css';

const CoverPages = ( {img,title, alt, classTitle} ) => {
    return (
        <header className='container-coverPages'>
            <div className='coverPages'>
                <div className='container-imgCoverPages'>
                    <img src={img} alt={alt} />
                    <div className='container-mainTitlesActivities'>
                        <h5 className={classTitle}>{title}</h5>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default CoverPages
