export const serviceDropdown = [
    {
        id: 1,
        title: "Acrobacia",
        path: "/acrobacia",
        class: "submenu-item",
    },
    {
        id: 2,
        title: "Cama Elástica",
        path: "/cama-elastica",
        class: "submenu-item",
    },
    {
        id: 3,
        title: "Circo",
        path: "/circo",
        class: "submenu-item",
    },
    {
        id: 4,
        title: "Dobles de Riesgo",
        path: "/dobles-de-riesgo",
        class: "submenu-item",
    },
    {
        id: 5,
        title: "Escalada",
        path: "/escalada",
        class: "submenu-item",
    },
    {
        id: 6,
        title: "Parada de Manos",
        path: "/parada-de-manos",
        class: "submenu-item",
    },
    {
        id: 7,
        title: "Parkour",
        path: "/park-our",
        class: "submenu-item",
    },
    {
        id: 8,
        title: "Shui Lu",
        path: "/shui-lu",
        class: "submenu-item",
    },
    {
        id: 9,
        title: "Tela",
        path: "/tela",
        class: "submenu-item",
    },
    {
        id: 10,
        title: "Trapecio",
        path: "/trapecio",
        class: "submenu-item",
    },
    
];