import './sectionImg.css';
import { Link } from 'react-router-dom';
import { GoCheck } from 'react-icons/go';

const SectionImg = () => {

    return (
        <div className='container-sectionActivities' id='actividades'>
            <div className='sectionActivities'>
                <div className='container-title-ActivitiesSection'>
                    <h3 className='title-ActivitiesSection'>Actividades</h3>
                    <span className='title-styleActivitiesSection-bottom'></span>
                </div>
                <div className="container-sectionImgActivities">
                    <div className='container-imgactivities'>
                        <Link to='/acrobacia' className='linkImgActivity'>
                            <div className='imgActivity-acrobacia'>
                                <h6 className='imgActivity-item'>Acrobacia</h6>
                            </div>
                        </Link>
                    </div>
                    <div className='container-imgactivities'>
                        <Link to='/cama-elastica' className='linkImgActivity'>
                            <div className='imgActivity-camaElastica'>
                                <h6 className='imgActivity-item'>Cama Elástica</h6>
                            </div>
                        </Link>
                    </div>
                    <div className='container-imgactivities'>
                        <Link to='/circo' className='linkImgActivity'>
                            <div className='imgActivity-circo'>
                                <h6 className='imgActivity-item'>Circo</h6>
                            </div>
                        </Link>
                    </div>
                    <div className='container-imgactivities'>
                        <Link to='/dobles-de-riesgo' className='linkImgActivity'>
                            <div className='imgActivity-dobleRiesgo'>
                                <h6 className='imgActivity-item'>Dobles de Riesgo</h6>
                            </div>
                        </Link>
                    </div>
                    <div className='container-imgactivities'>
                        <Link to='/escalada' className='linkImgActivity'>
                            <div className='imgActivity-escalada'>
                                <h6 className='imgActivity-item'>Escalada</h6>
                            </div>
                        </Link>
                    </div>
                    <div className='container-imgactivities'>
                        <Link to='/parada-de-manos' className='linkImgActivity'>
                            <div className='imgActivity-paradaDeManos'>
                                <h6 className='imgActivity-item'>Parada de Manos</h6>
                            </div>
                        </Link>
                    </div>
                    <div className='container-imgactivities'>
                        <Link to='/park-our' className='linkImgActivity'>
                            <div className='imgActivity-parkOur'>
                                <h6 className='imgActivity-item'>Parkour</h6>
                            </div>
                        </Link>
                    </div>
                    <div className='container-imgactivities'>
                        <Link to='/shui-lu' className='linkImgActivity'>
                            <div className='imgActivity-shuiLu'>
                                <h6 className='imgActivity-item'>Shui Lu</h6>
                            </div>
                        </Link>
                    </div>
                    <div className='container-imgactivities'>
                        <Link to='/tela' className='linkImgActivity'>
                            <div className='imgActivity-tela'>
                                <h6 className='imgActivity-item'>Tela</h6>
                            </div>
                        </Link>
                    </div>
                    <div className='container-imgactivities'>
                        <Link to='/trapecio' className='linkImgActivity'>
                            <div className='imgActivity-trapecio'>
                                <h6 className='imgActivity-item'>Trapecio</h6>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className='container-items-activities-description'>
                    <div className='container-items-check-description'>
                        <GoCheck className='items-check-description'/>
                        <h5 className='items-activities-description'>Clases para todas las edades</h5>
                    </div>
                </div>  
            </div>
            
        </div>
        
    )
}

export default SectionImg
