import '../../organisms/Navbar/navbar.css';
import { HiOutlineMail } from 'react-icons/hi';
import NavbarLinks from '../../atoms/Navbar/NavbarLinks';
import Logo from '../../atoms/Navbar/Logo';
import { useNavigate } from 'react-router-dom';

const Navigation = () => {

    const navigate = useNavigate();

    const onRedirection = () => {
        navigate('/contacto');
    };

    return (
        <div className='container-navbar'>
            <div className='container-logo'>
                <Logo/>
            </div>
            <div className='container-menuItems'>
                <NavbarLinks classes={'menuItems'}/>
                <div className='container-contactButton'>
                    <button className='contactButton' onClick={onRedirection}>
                        <HiOutlineMail className='contact-icon'/>
                        CONTACTO
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Navigation
