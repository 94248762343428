import '../pages.css';
import Footer from '../../components/organisms/Footer/Footer';
import ScheduleParkour from '../../components/molecules/ActivitiesPages/Schedule/ScheduleParkour';

const Parkour = () => {
    return (
        <div>
            <ScheduleParkour/>
            <Footer/>
        </div>
    )
}

export default Parkour
