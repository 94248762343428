import '../../organisms/Navbar/navbar.css';
import { Link } from 'react-router-dom';
import Dropdown from './Dropdown';
import { useState } from "react";
import { AiOutlineDown } from 'react-icons/ai';

const NavbarLinks = ( {classes} ) => {
    const [dropdown, setDropdown] = useState(false);

    const onClickDropdown = () => {
        setDropdown(dropdown);
    };

    return (
        <ul className={classes}>
            <Link to="/" ><li>INICIO</li></Link>
            <div className='activities'>
                <li
                    onMouseEnter={() => setDropdown(true)}
                    onMouseLeave={() => setDropdown(false)}
                    
                    onClick={onClickDropdown}
                >
                    <a href="#">ACTIVIDADES</a>
                    <AiOutlineDown className='upIcon' />
                    {dropdown && <Dropdown/>}  
                </li>
            </div>
        </ul>
    )
}

export default NavbarLinks
