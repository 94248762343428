import '../pages.css';
import Footer from '../../components/organisms/Footer/Footer';
import ScheduleDoblesRiesgo from '../../components/molecules/ActivitiesPages/Schedule/ScheduleDoblesRiesgo';

const DobleRiesgo = () => {
    return (
        <div>
            <ScheduleDoblesRiesgo/>
            <Footer/>
        </div>
    )
}

export default DobleRiesgo
