import ContactForm from '../../components/molecules/Contact/ContactForm'
import ContactMedia from '../../components/molecules/Contact/ContactMedia'
import Footer from '../../components/organisms/Footer/Footer'
import '../pages.css'

const Contact = () => {
    return (
        <div>
            <div className='container-contactForm-contactMedia'>
                <div className='contactForm-contactMedia'>
                    <ContactForm/>
                    <ContactMedia/>   
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Contact
