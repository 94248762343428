import './schedule.css';
import { Link } from 'react-router-dom';
import { GoCheck } from 'react-icons/go';
import { MdPlace } from 'react-icons/md';
import { FaChild,FaChevronRight } from 'react-icons/fa';
import Img from '../../../../assets/activities/trapecio20.jpg';
import Img2 from '../../../../assets/activities/trapecio10.jpg';
import Img3 from '../../../../assets/activities/trapecio1.jpg';

const ScheduleTrapecio = () => {
    return (
        <div className='container-ScheduleSection'>
            <div className='ScheduleSection trapecio'>
                <div className='description-ScheduleSection'>
                    <div className='container-subtitle-ScheduleSection'>
                        <h3 className='subtitle-ScheduleSection'>Clases</h3>
                        <span className='titleStyleBottom-ScheduleSection'></span>
                    </div>

                    <div className='container-cardsScheduleSection'>
                        <div className='container-cardSchedule'>
                            <div className='cardSchedule'>
                                <div className='container-imgInfoCardSchedule'>
                                    <img src={Img} alt='Trapecio'/>   
                                </div>
                                <div className='container-infoCardSchedule'>

                                    <h4 className='news-title activity'>Trapecio</h4>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <FaChevronRight className='icon-ScheduleSection'/>
                                        <h4 className='title-scheduleByGroup-ScheduleSection'>Días y horarios</h4>
                                    </div>
                                    <div className='container-spans-ScheduleSection'>
                                        <span className='days-ScheduleSection'>Martes, jueves y viernes de 19hs a 20.30hs, y de 20.30hs a 22hs.</span>
                                        <span className='days-ScheduleSection'>Martes y jueves de 10hs a 11.30hs.</span>
                                    </div>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <MdPlace className='icon-ScheduleSection'/>
                                        <span className='title-scheduleByGroup-ScheduleSection'>
                                            <a target='_blank' rel ='noreferrer' href='https://www.google.com.ar/maps/place/Espacio+Zero/@-34.5998552,-58.4450423,19z/data=!3m1!4b1!4m5!3m4!1s0x95bccb286276f137:0x8dd792f0ed9f2a4c!8m2!3d-34.5998485!4d-58.4444847'>
                                                Sede Malabia
                                            </a>
                                        </span> 
                                    </div>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <FaChild className='icon-ScheduleSection'/>
                                        <h4 className='title-scheduleByGroup-ScheduleSection'>Profe Sebastián Moyano</h4>
                                    </div>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <GoCheck className='icon-ScheduleSection'/>
                                        <span className='title-scheduleByGroup-ScheduleSection'><Link to='/contacto'>Abierta la inscripción</Link></span>
                                    </div>

                                </div>
                            </div> 
                        </div>

                        <div className='container-cardSchedule'>
                            <div className='cardSchedule'>
                                <div className='container-imgInfoCardSchedule'>
                                    <img src={Img3} alt='Trapecio a vuelo'/>   
                                </div>
                                <div className='container-infoCardSchedule'>

                                    <h4 className='news-title activity'>Trapecio a vuelo</h4>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <FaChevronRight className='icon-ScheduleSection'/>
                                        <h4 className='title-scheduleByGroup-ScheduleSection'>Días y horarios</h4>
                                    </div>
                                    <div className='container-spans-ScheduleSection'>
                                        <span className='days-ScheduleSection'>A convenir con las profesoras</span>
                                    </div>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <MdPlace className='icon-ScheduleSection'/>
                                        <span className='title-scheduleByGroup-ScheduleSection'>
                                            <a target='_blank' rel ='noreferrer' href='https://www.google.com.ar/maps/place/Espacio+Zero/@-34.5976138,-58.4386938,19.04z/data=!4m13!1m7!3m6!1s0x95bcca749491f22f:0x8c875f60fffdce13!2sMalabia+538,+C1414+DLL,+Buenos+Aires!3b1!8m2!3d-34.5976715!4d-58.4383148!3m4!1s0x95bcca7491ebef5b:0xc3176cbf7b975f92!8m2!3d-34.5978497!4d-58.4381629'>
                                                Sede Malabia
                                            </a>
                                        </span> 
                                    </div>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <FaChild className='icon-ScheduleSection'/>
                                        <h4 className='title-scheduleByGroup-ScheduleSection'>Profe Roxana Rodriguez</h4>
                                    </div>
                                    <div className='container-icon-span-ScheduleSection'>
                                        <FaChild className='icon-ScheduleSection'/>
                                        <h4 className='title-scheduleByGroup-ScheduleSection'>Profe Lorena Diaz</h4>
                                    </div>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <GoCheck className='icon-ScheduleSection'/>
                                        <span className='title-scheduleByGroup-ScheduleSection'><Link to='/contacto'>Abierta la inscripción</Link></span>
                                    </div>

                                </div>
                            </div> 
                        </div> 

                        <div className='container-cardSchedule'>
                            <div className='cardSchedule trapecio middle'>
                                <div className='container-imgInfoCardSchedule'>
                                    <img src={Img2} alt='Trapecio y Aro'/>   
                                </div>
                                <div className='container-infoCardSchedule'>

                                    <h4 className='news-title activity'>Trapecio y Aro</h4>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <FaChevronRight className='icon-ScheduleSection'/>
                                        <h4 className='title-scheduleByGroup-ScheduleSection'>Días y horarios</h4>
                                    </div>
                                    <div className='container-spans-ScheduleSection'>
                                        <span className='days-ScheduleSection'>Miércoles y viernes de 10hs a 11.30hs, y de 11.30hs a 13hs.</span>
                                        <span className='days-ScheduleSection'>Miércoles de 13hs a 14.30hs.</span>
                                        <span className='days-ScheduleSection'>Lunes y miércoles de 19hs a 20.30, y de 20.30hs a 22hs.</span>
                                        <span className='days-ScheduleSection'>Sábados de 10hs a 11.30hs.</span>
                                    </div>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <MdPlace className='icon-ScheduleSection'/>
                                        <span className='title-scheduleByGroup-ScheduleSection'>
                                            <a target='_blank' rel ='noreferrer' href='https://www.google.com.ar/maps/place/Espacio+Zero/@-34.5976138,-58.4386938,19.04z/data=!4m13!1m7!3m6!1s0x95bcca749491f22f:0x8c875f60fffdce13!2sMalabia+538,+C1414+DLL,+Buenos+Aires!3b1!8m2!3d-34.5976715!4d-58.4383148!3m4!1s0x95bcca7491ebef5b:0xc3176cbf7b975f92!8m2!3d-34.5978497!4d-58.4381629'>
                                                Sede Malabia
                                            </a>
                                        </span> 
                                    </div>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <FaChild className='icon-ScheduleSection'/>
                                        <h4 className='title-scheduleByGroup-ScheduleSection'>Profe Mane Stancato</h4>
                                    </div>
                                    <div className='container-icon-span-ScheduleSection'>
                                        <FaChild className='icon-ScheduleSection'/>
                                        <h4 className='title-scheduleByGroup-ScheduleSection'>Profe Pablo Daolio</h4>
                                    </div>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <GoCheck className='icon-ScheduleSection'/>
                                        <span className='title-scheduleByGroup-ScheduleSection'><Link to='/contacto'>Abierta la inscripción</Link></span>
                                    </div>

                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScheduleTrapecio
