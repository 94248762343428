import '../pages.css';
import Footer from '../../components/organisms/Footer/Footer';
import ScheduleParadaDeManos from '../../components/molecules/ActivitiesPages/Schedule/ScheduleParadaDeManos';

const ParadaDeManos = () => {
    return (
        <div>
            <ScheduleParadaDeManos/>
            <Footer/>
        </div>
    )
}

export default ParadaDeManos
