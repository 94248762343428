import './sectionOpinion.css';
import { serviceOpinion } from '../../atoms/SectionOpinion/Opinions';
import { FaQuoteRight, FaChevronRight } from 'react-icons/fa';
import { Swiper, SwiperSlide  } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import "swiper/css";
import 'swiper/css/navigation';

const SectionOpinion = () => {
    
    return (
        <div className='container-opinionSection'>
                <div className='container-title-opinionSection'>
                    <h3 className='title-opinionSection'>Opiniones de nuestra comunidad</h3>
                    <span className='title-style-bottom'></span>
                </div>
                <Swiper
                    modules={[Navigation, Autoplay]}
                    navigation={true}
                    centeredSlides={false}
                    spaceBetween={-40}
                    autoplay={{
                        delay: 6000,
                        disableOnInteraction: false
                    }}                
                    breakpoints={{
                        0: {
                        slidesPerView: 1,
                        slidesPerGroup: 1
                        },
                        700: {
                            slidesPerView: 2,
                            slidesPerGroup: 2
                        },
                        1000: {
                            slidesPerView: 3,
                            slidesPerGroup: 3,
                        },
                        1800: {
                            slidesPerView: 4,
                            slidesPerGroup: 4,
                        },
                    }}
                    speed={700}
                    loop={true}
                    direction= 'horizontal'
                    className="mySwiper"
                >
                    {serviceOpinion.map((item) => (
                        <SwiperSlide className='opinionSection' key={item.id}>
                            <div className='container-opinionCard' >
                                <FaQuoteRight className='icon-opinion'/>
                                <p className={item.classDescription}>
                                    {item.description}
                                </p>
                                <h5 className={item.className}>
                                    {item.name}
                                </h5>
                            </div>
                        </SwiperSlide>
                        ))}
                </Swiper>
                <div className='container-writeOpinion'>
                    <FaChevronRight className='icon-writeOpinion'/>
                    <a href='https://www.google.com/search?q=Espacio+Zero&biw=1920&bih=969&tbm=lcl&ei=8ZqwYsH3ObeL5OUPx9CR-A0&oq=Espacio+Zero&gs_l=psy-ab.12...0.0.0.23071.0.0.0.0.0.0.0.0..0.0....0...1c..64.psy-ab..0.0.0....0.hn4pij1XEQk#lrd=0x95bcca7491ebef5b:0xc3176cbf7b975f92,3,,,&rlfi=hd:;si:14057824331528363922,l,CgxFc3BhY2lvIFplcm9aFiIMZXNwYWNpbyB6ZXJvKgYIAhAAEAGSAQZzY2hvb2w;mv:[[-34.597500222680964,-58.43810355786575],[-34.59786017731903,-58.43854084213424]]'
                    target='_blank' rel ='noreferrer'>
                        <span className='writeOpinion'>Quiero escribir una opinión</span>
                    </a>
                </div>
            
        </div>
        
    )
}

export default SectionOpinion
