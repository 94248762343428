import './schedule.css';
import { Link } from 'react-router-dom';
import { GoCheck } from 'react-icons/go';
import { MdPlace } from 'react-icons/md';
import { FaChevronRight } from 'react-icons/fa';
import circoKidsImg from '../../../../assets/activities/elongacion.png';
import circoAdolescentesImg from '../../../../assets/activities/elongacion.png';


const CircoSchedule = () => {
    return (
        <div className='container-ScheduleSection'>
            <div className='ScheduleSection'>
                <div className='description-ScheduleSection'>
                    <div className='container-subtitle-ScheduleSection'>
                        <h3 className='subtitle-ScheduleSection'>Clases</h3>
                        <span className='titleStyleBottom-ScheduleSection'></span>
                    </div>

                    <div className='container-cardsScheduleSection'>
                        <div className='container-cardSchedule'>
                            <div className='cardSchedule circo'>
                                <div className='container-imgInfoCardSchedule'>
                                    <img src={circoKidsImg} alt='Circo para Niñxs'/>   
                                </div>
                                <div className='container-infoCardSchedule'>

                                    <h4 className='news-title activity'>Circo para Niñxs</h4>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <FaChevronRight className='icon-ScheduleSection'/>
                                        <h4 className='title-scheduleByGroup-ScheduleSection'>Grupo de 9 a 12 años</h4>
                                    </div>
                                    <div className='container-spans-ScheduleSection'>
                                        <span className='days-ScheduleSection'>Martes a viernes 17.30hs</span>
                                        <span className='days-ScheduleSection'>Sábados 12hs</span>
                                    </div>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <FaChevronRight className='icon-ScheduleSection'/>
                                        <h4 className='title-scheduleByGroup-ScheduleSection'>Grupo de 6 a 8 años</h4>
                                    </div>
                                    <div className='container-spans-ScheduleSection'>
                                        <span className='days-ScheduleSection'>Martes a viernes 17.30hs</span>
                                        <span className='days-ScheduleSection'>Sábados 12hs</span>
                                    </div>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <FaChevronRight className='icon-ScheduleSection'/>
                                        <h4 className='title-scheduleByGroup-ScheduleSection'>Grupo de 4 y 5 años</h4>
                                    </div>
                                    <div className='container-spans-ScheduleSection'>
                                    <span className='days-ScheduleSection'>Jueves 16.30hs</span>
                                    </div>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <MdPlace className='icon-ScheduleSection'/>
                                        <span className='title-scheduleByGroup-ScheduleSection'>
                                            <a target='_blank' rel ='noreferrer' href='https://www.google.com.ar/maps/place/Espacio+Zero/@-34.5976138,-58.4386938,19.04z/data=!4m13!1m7!3m6!1s0x95bcca749491f22f:0x8c875f60fffdce13!2sMalabia+538,+C1414+DLL,+Buenos+Aires!3b1!8m2!3d-34.5976715!4d-58.4383148!3m4!1s0x95bcca7491ebef5b:0xc3176cbf7b975f92!8m2!3d-34.5978497!4d-58.4381629'>
                                                Sede Malabia
                                            </a>
                                        </span> 
                                    </div>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <GoCheck className='icon-ScheduleSection'/>
                                        <span className='title-scheduleByGroup-ScheduleSection'><Link to='/contacto'>Abierta la inscripción</Link></span>
                                    </div>

                                </div>
                            </div> 
                        </div>

                        <div className='container-cardSchedule'>
                            <div className='cardSchedule circo middle'>
                                <div className='container-imgInfoCardSchedule'>
                                    <img src={circoAdolescentesImg} alt='Circo para Adolescentes'/>   
                                </div>
                                <div className='container-infoCardSchedule'>

                                    <h4 className='news-title activity'>Circo para Adolescentes</h4>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <FaChevronRight className='icon-ScheduleSection'/>
                                        <h4 className='title-scheduleByGroup-ScheduleSection'>Días y horarios</h4>
                                    </div>
                                    <div className='container-spans-ScheduleSection'>
                                        <span className='days-ScheduleSection'>Lunes 17.30hs</span>
                                        <span className='days-ScheduleSection'>Sábados 13.30hs</span>
                                    </div>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <MdPlace className='icon-ScheduleSection'/>
                                        <span className='title-scheduleByGroup-ScheduleSection'>
                                            <a target='_blank' rel ='noreferrer' href='https://www.google.com.ar/maps/place/Espacio+Zero/@-34.5976138,-58.4386938,19.04z/data=!4m13!1m7!3m6!1s0x95bcca749491f22f:0x8c875f60fffdce13!2sMalabia+538,+C1414+DLL,+Buenos+Aires!3b1!8m2!3d-34.5976715!4d-58.4383148!3m4!1s0x95bcca7491ebef5b:0xc3176cbf7b975f92!8m2!3d-34.5978497!4d-58.4381629'>
                                                Sede Malabia
                                            </a>
                                        </span> 
                                    </div>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <GoCheck className='icon-ScheduleSection'/>
                                        <span className='title-scheduleByGroup-ScheduleSection'><Link to='/contacto'>Abierta la inscripción</Link></span>
                                    </div>

                                </div>
                            </div> 
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default CircoSchedule
