import './navbar.css';
import Navigation from '../../molecules/Navbar/Navigation';
import MobileNavigation from '../../molecules/Navbar/MobileNavigation';
import SidebarMenu from '../../molecules/Navbar/SidebarMenu';
import { useState } from 'react';

const Navbar = () => {

    const [sidebarOpen, setSidebarOpen] = useState(false);

    const onClickSidebarOpen = (newValue) => {
        setSidebarOpen(newValue);
    };

    return (
        <nav>
            <Navigation/>
            <MobileNavigation sidebarOpen={sidebarOpen} onClickSidebarOpen={onClickSidebarOpen} />
            {sidebarOpen && <SidebarMenu sidebarOpen={sidebarOpen} onClickSidebarOpen={onClickSidebarOpen}/>}
        </nav>
    )
}

export default Navbar
