export const serviceOpinion = [
    {
        id: 1,
        name: "Florencia",
        description: "Excelente la atención! Todos son mega amigables y buena onda. Me encanta este lugar, super recomendado.",
        className: "opinion-item",
        classDescription: "opinion",
    },
    {
        id: 2,
        name: "Nahuel",
        description: "Excelente lugar para aprender, divertirse y entrenar. Realmente lo recomiendo.",
        className: "opinion-item",
        classDescription: "opinion",
    },
    {
        id: 3,
        name: "Marisol",
        description: "Excelentes docentes. Se destaca el nivel de conocimiento y la predisposición para enseñar.",
        className: "opinion-item",
        classDescription: "opinion",
    },
    {
        id: 4,
        name: "Amparo",
        description: "Una escuela donde siempre encontrarás amigos, compañeros, y profes con muchas ganas de enseñar y de que progreses.",
        className: "opinion-item",
        classDescription: "opinion",
    },
    {
        id: 5,
        name: "Susana",
        description: "Un espacio mágico donde volar y ver el mundo desde otro lugar. Amigos y profes lo mejor de este lugar.",
        className: "opinion-item",
        classDescription: "opinion",
    },
    {
        id: 6,
        name: "Facundo",
        description: "Excelente lugar y lo más importante el factor humano. Muy buen trato y aprendizaje.",
        className: "opinion-item",
        classDescription: "opinion",
    },
    {
        id: 7,
        name: "Eugenia",
        description: "Las clases súper divertidas! Hay para todos los niveles, y los profes son los más!!!.",
        className: "opinion-item",
        classDescription: "opinion",
    },
    {
        id: 8,
        name: "Natalia",
        description: "El lugar más lindo para que mi hijo aprenda circo!!! Un lujo!! Los profesores ideales!",
        className: "opinion-item",
        classDescription: "opinion",
    },
    {
        id: 9,
        name: "Diego",
        description: "Un muy buen lugar donde perderse de la rutina!! Entrenar, estar con amigos, y pasarla muy bien con toda la buena onda.",
        className: "opinion-item",
        classDescription: "opinion",
    },
    {
        id: 10,
        name: "Mateo",
        description: "La mejor escuela! El amor, la buena onda de las personas y la alegría que hay acá no la encontras en otro lado!!",
        className: "opinion-item",
        classDescription: "opinion",
    },
    {
        id: 11,
        name: "Marcela",
        description: "Excelente espacio! Buena conducción y muy buenos docentes. Dedicación y esfuerzo.",
        className: "opinion-item",
        classDescription: "opinion",
    },
];