import cover1Img from '../../../assets/activities/aro6.JPG';
import cover2Img from '../../../assets/activities/aro5.JPG';
import cover3Img from '../../../assets/activities/aro4.JPG';
import cover4Img from '../../../assets/activities/trapecio7.JPG';
import cover5Img from '../../../assets/activities/trapecio9.JPG';

export const serviceImg = [
    {
        id: 1,
        title: 'Acrobacia',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
        img: cover1Img,
        classTitle: 'mainTitleActivity',
        classDescription: 'mainDescriptionActivity',
        classMainContainer: 'mainContainer'
    },
    {
        id: 2,
        title: 'Cama Elástica',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
        img: cover2Img,
        classTitle: 'mainTitleActivity',
        classDescription: 'mainDescriptionActivity',
        classMainContainer: 'mainContainer'
    },
    {
        id: 3,
        title: 'Circo',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
        img: cover3Img,
        classTitle: 'mainTitleActivity',
        classDescription: 'mainDescriptionActivity',
        classMainContainer: 'mainContainer'
    },
    {
        id: 4,
        title: 'Doble Riesgo',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
        img: cover4Img,
        classTitle: 'mainTitleActivity',
        classDescription: 'mainDescriptionActivity',
        classMainContainer: 'mainContainer'
    },
    {
        id: 5,
        title: 'Escalada',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
        img: cover5Img,
        classTitle: 'mainTitleActivity',
        classDescription: 'mainDescriptionActivity',
        classMainContainer: 'mainContainer'
    }
];