import '../pages.css';
import Footer from '../../components/organisms/Footer/Footer';
import ScheduleEscalada from '../../components/molecules/ActivitiesPages/Schedule/ScheduleEscalada';

const Escalada = () => {
    return (
        <div>
            <ScheduleEscalada/>
            <Footer/>
        </div>
    )
}

export default Escalada
