import '../../organisms/Navbar/navbar.css';
import { BsXLg } from 'react-icons/bs';
import Logo from '../../atoms/Navbar/Logo';
import NavbarLinks from '../../atoms/Navbar/NavbarLinks';
import NavbarLinksMobile from '../../atoms/Navbar/NavbarLinksMobile';

const SidebarMenu = ( {sidebarOpen, onClickSidebarOpen} ) => {

    const onClickSidebar = () => {
        onClickSidebarOpen(!sidebarOpen);
    };
    const onClickLink = () => {
        onClickSidebarOpen(!sidebarOpen);
    };

    return (
        <div className='container-sidebarMenuItems'>
            <div className='sidebarMenuItems'>
                <div className='containter-iconMenu-sidebar'>
                    <BsXLg className='iconMenu-sidebar' onClick={onClickSidebar}/>
                </div>
                <div className='container-logoSidebar' onClick={onClickSidebar}>
                    <Logo />
                </div>
            </div>
            <div className='container-menuItemsSidebar'>
               {!sidebarOpen && <NavbarLinks classes={'menuItemsSidebar'}/>}
               {sidebarOpen && <NavbarLinksMobile classes={'menuItemsSidebar'}
               sidebarOpen={sidebarOpen}
               onClick={onClickLink}/>}
            </div>
        </div>
    )
}

export default SidebarMenu
