import './schedule.css';
import { Link } from 'react-router-dom';
import { GoCheck } from 'react-icons/go';
import { MdPlace } from 'react-icons/md';
import { FaChild,FaChevronRight } from 'react-icons/fa';
import Img1 from '../../../../assets/activities/elongacion.png';
import Img2 from '../../../../assets/activities/elongacion.png';
import Img3 from '../../../../assets/activities/parkour2.jpg';
import Img4 from '../../../../assets/activities/parkour3.jpg';

const ScheduleParkour = () => {
    return (
        <div className='container-ScheduleSection'>
            <div className='ScheduleSection parkour'>
                <div className='description-ScheduleSection parkour'>
                    <div className='container-subtitle-ScheduleSection'>
                        <h3 className='subtitle-ScheduleSection'>Clases</h3>
                        <span className='titleStyleBottom-ScheduleSection'></span>
                    </div>

                    <div className='container-cardsScheduleSection'>
                        <div className='container-cardSchedule'>
                            <div className='cardSchedule parkour'>
                                <div className='container-imgInfoCardSchedule'>
                                    <img src={Img1} alt='Parkour para 4 y 5 años'/>   
                                </div>
                                <div className='container-infoCardSchedule'>

                                    <h4 className='news-title activity'>Parkour para 4 y 5 años</h4>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <FaChevronRight className='icon-ScheduleSection'/>
                                        <h4 className='title-scheduleByGroup-ScheduleSection'>Días y horarios</h4>
                                    </div>
                                    <div className='container-spans-ScheduleSection'>
                                        <span className='days-ScheduleSection'>Miércoles y viernes 16.45hs.</span>
                                    </div>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <MdPlace className='icon-ScheduleSection'/>
                                        <span className='title-scheduleByGroup-ScheduleSection'>
                                            <a target='_blank' rel ='noreferrer' href='https://www.google.com.ar/maps/place/Espacio+Zero/@-34.5998552,-58.4450423,19z/data=!3m1!4b1!4m5!3m4!1s0x95bccb286276f137:0x8dd792f0ed9f2a4c!8m2!3d-34.5998485!4d-58.4444847'>
                                                Sede Murillo
                                            </a>
                                        </span> 
                                    </div>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <GoCheck className='icon-ScheduleSection'/>
                                        <span className='title-scheduleByGroup-ScheduleSection'><Link to='/contacto'>Abierta la inscripción</Link></span>
                                    </div>

                                </div>
                            </div> 
                        </div>

                        <div className='container-cardSchedule'>
                            <div className='cardSchedule parkour'>
                                <div className='container-imgInfoCardSchedule'>
                                    <img src={Img2} alt='Parkour para Niñxs'/>   
                                </div>
                                <div className='container-infoCardSchedule'>

                                    <h4 className='news-title activity'>Parkour para Niñxs</h4>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <FaChevronRight className='icon-ScheduleSection'/>
                                        <h4 className='title-scheduleByGroup-ScheduleSection'>Días y horarios</h4>
                                    </div>
                                    <div className='container-spans-ScheduleSection'>
                                        <span className='days-ScheduleSection'>Lunes, miércoles y viernes 18hs.</span>
                                        <span className='days-ScheduleSection'>Sábados 12hs.</span>
                                    </div>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <MdPlace className='icon-ScheduleSection'/>
                                        <span className='title-scheduleByGroup-ScheduleSection'>
                                            <a target='_blank' rel ='noreferrer' href='https://www.google.com.ar/maps/place/Espacio+Zero/@-34.5998552,-58.4450423,19z/data=!3m1!4b1!4m5!3m4!1s0x95bccb286276f137:0x8dd792f0ed9f2a4c!8m2!3d-34.5998485!4d-58.4444847'>
                                                Sede Murillo
                                            </a>
                                        </span> 
                                    </div>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <GoCheck className='icon-ScheduleSection'/>
                                        <span className='title-scheduleByGroup-ScheduleSection'><Link to='/contacto'>Abierta la inscripción</Link></span>
                                    </div>

                                </div>
                            </div> 
                        </div>

                        <div className='container-cardSchedule'>
                            <div className='cardSchedule parkour middle'>
                                <div className='container-imgInfoCardSchedule'>
                                    <img src={Img3} alt='Parkour para Adolescentes'/>   
                                </div>
                                <div className='container-infoCardSchedule'>

                                    <h4 className='news-title activity'>Parkour para Adolescentes</h4>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <FaChevronRight className='icon-ScheduleSection'/>
                                        <h4 className='title-scheduleByGroup-ScheduleSection'>Días y horarios</h4>
                                    </div>
                                    <div className='container-spans-ScheduleSection'>
                                        <span className='days-ScheduleSection'>Martes y jueves 18hs.</span>
                                        <span className='days-ScheduleSection'>Sábados 14hs y 16hs.</span>
                                    </div>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <MdPlace className='icon-ScheduleSection'/>
                                        <span className='title-scheduleByGroup-ScheduleSection'>
                                            <a target='_blank' rel ='noreferrer' href='https://www.google.com.ar/maps/place/Espacio+Zero/@-34.5998552,-58.4450423,19z/data=!3m1!4b1!4m5!3m4!1s0x95bccb286276f137:0x8dd792f0ed9f2a4c!8m2!3d-34.5998485!4d-58.4444847'>
                                                Sede Murillo
                                            </a>
                                        </span> 
                                    </div>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <FaChild className='icon-ScheduleSection'/>
                                        <h4 className='title-scheduleByGroup-ScheduleSection'>Profe Josue Di Stefano</h4>
                                    </div>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <GoCheck className='icon-ScheduleSection'/>
                                        <span className='title-scheduleByGroup-ScheduleSection'><Link to='/contacto'>Abierta la inscripción</Link></span>
                                    </div>

                                </div>
                            </div> 
                        </div>

                        <div className='container-cardSchedule'>
                            <div className='cardSchedule parkour big'>
                                <div className='container-imgInfoCardSchedule'>
                                    <img src={Img4} alt='Parkour para Adultos'/>   
                                </div>
                                <div className='container-infoCardSchedule'>

                                    <h4 className='news-title activity'>Parkour para Adultos</h4>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <FaChevronRight className='icon-ScheduleSection'/>
                                        <h4 className='title-scheduleByGroup-ScheduleSection'>Días y horarios</h4>
                                    </div>
                                    <div className='container-spans-ScheduleSection'>
                                        <span className='days-ScheduleSection'>Lunes, miércoles y viernes 19.45hs.</span>
                                        <span className='days-ScheduleSection'>Martes, jueves y sábados 19.45hs.</span>
                                        <span className='days-ScheduleSection'>Martes y jueves 10hs.</span>
                                    </div>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <MdPlace className='icon-ScheduleSection'/>
                                        <span className='title-scheduleByGroup-ScheduleSection'>
                                            <a target='_blank' rel ='noreferrer' href='https://www.google.com.ar/maps/place/Espacio+Zero/@-34.5998552,-58.4450423,19z/data=!3m1!4b1!4m5!3m4!1s0x95bccb286276f137:0x8dd792f0ed9f2a4c!8m2!3d-34.5998485!4d-58.4444847'>
                                                Sede Murillo
                                            </a>
                                        </span> 
                                    </div>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <FaChild className='icon-ScheduleSection'/>
                                        <h4 className='title-scheduleByGroup-ScheduleSection'>Profe Josue Di Stefano</h4>
                                    </div>
                                    <div className='container-icon-span-ScheduleSection'>
                                        <FaChild className='icon-ScheduleSection'/>
                                        <span className='title-scheduleByGroup-ScheduleSection'>Profe Kami Gakko</span>
                                    </div>
                                    <div className='container-icon-span-ScheduleSection'>
                                        <FaChild className='icon-ScheduleSection'/>
                                        <span className='title-scheduleByGroup-ScheduleSection'>Profe Ezequiel Duarte</span>
                                    </div>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <GoCheck className='icon-ScheduleSection'/>
                                        <span className='title-scheduleByGroup-ScheduleSection'><Link to='/contacto'>Abierta la inscripción</Link></span>
                                    </div>

                                </div>
                            </div> 
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScheduleParkour
