import '../pages.css';
import Footer from '../../components/organisms/Footer/Footer';
import ScheduleTela from '../../components/molecules/ActivitiesPages/Schedule/ScheduleTela';

const Tela = () => {
    return (
        <div>
            <ScheduleTela/>
            <Footer/>
        </div>
    )
}

export default Tela
