import '../pages.css';
import Cover from '../../components/atoms/Cover/Cover';
import Footer from '../../components/organisms/Footer/Footer';
import SectionOpinion from '../../components/organisms/SectionOpinion/SectionOpinion';
import SectionImg from '../../components/organisms/SectionImgActivities/SectionImg';
import SectionInscription from '../../components/organisms/SectionInscription/SectionInscription';

const Home = () => {
    return (
        <div>
            <Cover />
            <SectionImg/>
            <SectionInscription/>
            <SectionOpinion/>
            <Footer/>
        </div>
        
    )
}

export default Home
