import './cover.css';
import { Swiper, SwiperSlide  } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { serviceImg } from './imgCover';

const Cover = () => {

    return (
        <header className='container-cover'>
            <div className='container-video'>
                <Swiper
                modules={[Autoplay, Pagination]}
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: false
                }}
                speed={800}
                loop={true}
                direction= 'horizontal'
                className="mySwiper"
                >
                    <div className='mainContainer'>
                        <div className='container-mainContent'>
                            {/* <h5 className='mainTitleActivity'>Espacio Zero</h5> */}
                        </div>
                    </div>
                    {serviceImg.map((item) => (
                        <SwiperSlide className='imgCover' key={item.id}>
                            <img src={item.img} alt='Img' />
                        </SwiperSlide>
                        ))}
                </Swiper>
            </div>
        </header>
    )
}

export default Cover
