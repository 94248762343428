import { useState } from "react";
import { serviceDropdown } from "./navItems";
import { Link } from "react-router-dom";
import '../../organisms/Navbar/navbar.css';

function DropdownMobile( {sidebarOpen, onClick} ) {

  const [dropdown, setDropdown] = useState(false);
  const onCloseSidebar = () => {
    onClick(!sidebarOpen);
  };

  return (
    <>
      <ul
        className="services-submenu"
        
        onClick={() => setDropdown(!dropdown)}
      >
        {serviceDropdown.map((item) => {
          return (
            <li key={item.id}>
              <Link
                to={item.path}
                className={item.class}
                onFocus={() => setDropdown(false)}
                onClick={onCloseSidebar}
              >
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default DropdownMobile;