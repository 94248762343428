import './contactMedia.css';
import { AiOutlineInstagram } from 'react-icons/ai';
import { MdMail } from 'react-icons/md';
import contactImg from '../../../assets/contact.png'

const ContactMedia = () => {
    return (
        <div className='container-ContactSectionMedia'>
            <div className='contactSectionMedia'>
                <div className='container-title-ContactSectionMedia'>
                    <h3 className='title-ContactSectionMedia'>Otras maneras de comunicarte</h3>
                    <span className='titleStyleBottom-ContactSectionMedia'></span>
                </div>
                <div className='container-media-ContactSectionMedia'>
                    <div className='container-items-ContactSectionMedia'>
                        <a href="https://www.instagram.com/espacio.zero" target='blank'>
                            <AiOutlineInstagram className="icons-ContactSectionMedia"/>
                            <span className='items-ContactSectionMedia'>@espacio.zero</span>
                        </a>
                    </div>
                    <div className='container-items-ContactSectionMedia'>
                        <a href='mailto:info@espaciozero.com.ar?Subject=Consulta'>
                            <MdMail className='icons-ContactSectionMedia'/>
                            <span className='items-ContactSectionMedia'>info@espaciozero.com.ar</span>
                        </a>
                    </div>
                </div>
            </div>
            <div className='contactImg'>
                <img src={contactImg}></img>
            </div>
        </div>
    )
}

export default ContactMedia
