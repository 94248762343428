import '../pages.css';
import Footer from '../../components/organisms/Footer/Footer';
import ScheduleCirco from '../../components/molecules/ActivitiesPages/Schedule/ScheduleCirco';

const Circo = () => {
    return (
        <div>
            <ScheduleCirco/>
            <Footer/>
        </div>
    )
}

export default Circo
