import './schedule.css';
import { Link } from 'react-router-dom';
import { GoCheck } from 'react-icons/go';
import { MdPlace } from 'react-icons/md';
import { FaChevronRight } from 'react-icons/fa';
import acrobaciaImg from '../../../../assets/activities/dobleriesgo2.jpg';

const ScheduleDoblesRiesgo = () => {
    return (
        <div className='container-ScheduleSection'>
            <div className='ScheduleSection'>
                <div className='description-ScheduleSection'>
                    <div className='container-subtitle-ScheduleSection'>
                        <h3 className='subtitle-ScheduleSection'>Clases</h3>
                        <span className='titleStyleBottom-ScheduleSection'></span>
                    </div>

                    <div className='container-cardSchedule'>
                            <div className='cardSchedule'>
                                <div className='container-imgInfoCardSchedule'>
                                    <img src={acrobaciaImg} alt='Dobles de Riesgo'/>   
                                </div>
                                <div className='container-infoCardSchedule'>

                                    <h4 className='news-title activity'>Dobles de Riesgo</h4>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <FaChevronRight className='icon-ScheduleSection'/>
                                        <h4 className='title-scheduleByGroup-ScheduleSection'>Días y horarios</h4>
                                    </div>
                                    <div className='container-spans-ScheduleSection'>
                                        <span className='days-ScheduleSection'>Miércoles 14hs.</span>
                                        <span className='days-ScheduleSection'>Sábados 10hs.</span>
                                    </div>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <MdPlace className='icon-ScheduleSection'/>
                                        <span className='title-scheduleByGroup-ScheduleSection'>
                                            <a target='_blank' rel ='noreferrer' href='https://www.google.com.ar/maps/place/Espacio+Zero/@-34.5998552,-58.4450423,19z/data=!3m1!4b1!4m5!3m4!1s0x95bccb286276f137:0x8dd792f0ed9f2a4c!8m2!3d-34.5998485!4d-58.4444847'>
                                                Sede Murillo
                                            </a>
                                        </span> 
                                    </div>

                                    <div className='container-icon-span-ScheduleSection'>
                                        <GoCheck className='icon-ScheduleSection'/>
                                        <span className='title-scheduleByGroup-ScheduleSection'><Link to='/contacto'>Abierta la inscripción</Link></span>
                                    </div>

                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScheduleDoblesRiesgo
